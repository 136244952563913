var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"a101@1.7.6"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
	dsn:
		SENTRY_DSN ||
		`https://b87302b4ca4b42859d08e279b191be6a@o437901.ingest.sentry.io/4505030101827584`,
	// Ignore particular errors from being sent to Sentry.
	// In November 2023 we went over our limit because of an infinite loop
	// of ResizeObserver errors users experienced in the Pour Interactive.
	ignoreErrors: [
		"ResizeObserver loop limit exceeded",
		"ResizeObserver loop completed with undelivered notifications.",
		"Non-Error promise rejection captured with value: We detected a problem with the configuration of this widget, please contact your website admin.",
		"Beacons can only be sent over HTTP(S)",
	],
	// Adjust this value in production, or use tracesSampler for greater control
	tracesSampleRate: 0.25,
	// ...
	// Note: if you want to override the automatic release value, do not set a
	// `release` value here - use the environment variable `SENTRY_RELEASE`, so
	// that it will also get attached to your source maps

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,
	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1,

	enabled: process.env.NODE_ENV === `production` && !process.env.TEST,

	integrations: [new Sentry.Replay()],
});
